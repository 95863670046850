import { useState, useEffect } from "react";
import { Cloudinary as CoreCloudinary, Util } from "cloudinary-core";

import {
  assetsEndpoint,
  cloudinaryCloudName,
  cloudinaryPhotoPreset,
  cloudinaryItinerariesPath,
  cloudinaryProfilesPath,
} from "@/config";
import theme from "./theme";

/*
 * CONFIG & SETTINGS
 */

const MAX_SLOTS = 20;
const MAX_IMAGE_SIZE = 10000000;
const MAX_VIDEO_SIZE = 400000000;
const UPLOADER_WIDGET_PATH =
  "https://upload-widget.cloudinary.com/global/all.js";
const ALLOWED_FORMATS = [
  "png",
  "gif",
  "jpg",
  "jpeg",
  "tiff",
  "bmp",
  "avi",
  "mov",
  "mp4",
  "mpeg",
  "ogv",
  "webm",
];
const UPLOAD_SOURCES = [
  "local",
  "facebook",
  "instagram",
  "dropbox",
  "google_drive",
];

const cloudinaryImagesUrl = `https://res.cloudinary.com/${cloudinaryCloudName}/image`;
const uploaderId = "cloudUploader";
const cloudinaryCore = CoreCloudinary.new({
  cloud_name: cloudinaryCloudName,
  secure: true,
});

// upload widget theme
const styles = {
  palette: {
    window: theme.palette.common.white,
    windowBorder: theme.palette.primary.light,
    tabIcon: theme.palette.primary.main,
    menuIcons: theme.palette.primary.main,
    textDark: theme.palette.common.black,
    textLight: theme.palette.common.white,
    link: theme.palette.secondary.main,
    action: theme.palette.secondary.main,
    inactiveTabIcon: theme.palette.grey[700],
    error: theme.palette.error.main,
    inProgress: theme.palette.secondary.main,
    complete: theme.palette.secondary.main,
    sourceBg: theme.palette.grey[100],
  },
  fonts: {
    "'Source Sans Pro', sans-serif":
      "https://fonts.googleapis.com/css?family=Source+Sans+Pro",
  },
};

const translation = {
  en: {
    crop: {
      skip_btn: "Next",
    },
  },
};

const defaultTransformations = {
  crop: "limit",
  dpr: "auto",
  quality: "auto",
  height: 1500,
  width: 2500,
};

/*
 * PRIVATE UTILITIES
 */

// embeds segments script
const embedScript = (path, id) => {
  // check if exist
  if (document.getElementById(id)) return;
  // Create an async script element based on your key.
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.defer = true;
  script.src = path;
  script.setAttribute("id", id);

  // Insert our script next to the first script element.
  const first = document.getElementsByTagName("script")[0];
  first.parentNode.insertBefore(script, first);
};

// options passed to widget when it's built
const buildWidgetOptions = (options) => {
  return Util.withSnakeCaseKeys({
    analytics: true,
    cloudName: cloudinaryCloudName,
    focus: true,
    uploadPreset: cloudinaryPhotoPreset,
    maxImageFileSize: MAX_IMAGE_SIZE,
    maxVideoFileSize: MAX_VIDEO_SIZE,
    maxFiles: MAX_SLOTS,
    // resourceType: "image",
    clientAllowedFormats: ALLOWED_FORMATS,
    secure: true,
    sources: UPLOAD_SOURCES,
    theme: "minimal",
    showPoweredBy: false,
    styles,
    language: "en",
    text: translation,
    ...options,
  });
};

// convert the raw cloud data to AG image object
const convertDataToImage = (imgs) => {
  return (imgs || []).map(({ uploadInfo }) => {
    const {
      public_id = "",
      thumbnail_url = "",
      secure_url = "",
      context = "",
    } = uploadInfo || {};
    const { alt = "", caption = "" } = context?.custom || {};
    return {
      alt,
      caption,
      cloudId: public_id,
      thumbUrl: thumbnail_url,
      photoUrl: secure_url,
    };
  });
};

/*
 * UTILITIES
 */

export const allowedVideoExt = "avi|mov|mp4|mpeg|ogv|webm";

export const getUserCloudId = (url = "") => {
  const ext = "(.[^ .]+)";
  const regEx = new RegExp(`${cloudinaryProfilesPath}(.*?).${ext}`, "gi");
  const cloudId = url?.match(regEx)?.[0] || "";
  return cloudId;
};

// TODO: must be moved to a ENV variable for misc
export const cloudinaryEnvPath = cloudinaryItinerariesPath.replace(
  "/itineraries",
  ""
);

// utility to migrate image from CDN to Cloudinary
export const uploadRemoteSource = (
  url = "",
  transformation = defaultTransformations
) => {
  // returns when localhost or cloudinary url
  if (
    !url.includes("static") ||
    url.includes(".cdn") ||
    url.includes("_next") ||
    url.includes("googleapis")
  ) {
    return url;
  }
  const relativeUrl = url.replace(`${assetsEndpoint}/static/img/`, "");
  // const cloudId = relativeUrl.replace(/\.[^/.]+$/, "")
  const qsTransformation = cloudinaryCore.transformation(transformation);
  const cloudResource = `${cloudinaryImagesUrl}/upload/${qsTransformation}/${cloudinaryEnvPath}/misc/${relativeUrl}`;
  return cloudResource;
};

export const checkIsVideoCloudId = (cloudId) => cloudId.includes("/videos/");

// check if filename contains video format
export function checkIsVideoFormat(filename = "") {
  const ext = (filename || "").toLowerCase().split(".").pop();
  return allowedVideoExt.includes(ext);
}

// utility to create cloud asset path
export const buildUrl = (
  cloudId = "",
  pretransformations = {},
  postTransformations = {}
) => {
  if (!cloudId) return "";
  const isVideoFile = checkIsVideoCloudId(cloudId); // the file is into a video folder

  // CloudinaryCore.video_url does not accept chained transformations
  // Chained transformations are used to apply filters and post transformations
  // TODO: Replace core by @cloudinary/url-gen
  // https://cloudinary.com/documentation/image_transformations#chained_transformations
  if (allowedVideoExt.includes(pretransformations?.format) || isVideoFile) {
    const mergedTransformations = {
      ...pretransformations,
      ...postTransformations,
    };
    return cloudinaryCore.video_url(cloudId, mergedTransformations);
  } else {
    const chainedTransformations =
      cloudinaryCore.transformation(pretransformations);
    if (chainedTransformations) {
      chainedTransformations.chain().transformation(postTransformations);
    }
    return cloudinaryCore.url(cloudId, chainedTransformations);
  }
};

export const cloudinaryImageLoader = (cloudId) => {
  // console.log("cloudinaryImageLoader:: ", `${cloudinaryImagesUrl}/upload/e_blur:1000,q_1/v1/${cloudId}`);
  return `${cloudinaryImagesUrl}/upload/e_blur:1000,q_1/v1/${cloudId}`;
  // return buildUrl(
  //   cloudId,
  //   {
  //     ...transformations,
  //     effect: "blur:1000",
  //     quality: 1,
  //   }
  // );
};

/*
 * HOOK
 */

const useCloudinary = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [response, setResponse] = useState({});

  // callback fired when user upload a photo
  const handleUploadStatus = (err, res) => {
    if (err) {
      setError(err);
      setLoading(false);
      return;
    }
    setResponse({ event: res.event, data: res.info?.files });
  };

  // open uploader widget
  const handleOpenUpload = async (options) => {
    setImages([]);
    setOpen(true);
    setLoading(true);
    setError(null);

    await window.cloudinary.openUploadWidget(
      buildWidgetOptions(options),
      handleUploadStatus
    );
  };

  // embeds scripts when component is mounted
  useEffect(() => {
    embedScript(UPLOADER_WIDGET_PATH, uploaderId);
  }, []);

  // keeps record of cloudinary status
  useEffect(() => {
    const { event, data } = response;

    // console.log("cloudinary:: ", { event, data });
    // debugger;

    if (event) {
      switch (event) {
        case "queues-start":
          setImages([]);
          setLoading(true);
          break;
        case "queues-end":
          if (data) setImages(convertDataToImage(data));
          setLoading(false);
          break;
        case "close":
          setImages([]);
          setLoading(false);
          setOpen(false);
          break;
        case "abort":
        case "success":
        case "source-changed":
          setImages([]);
          setLoading(false);
          break;
        case "display-changed":
          if (!data) {
            setOpen(false);
            setLoading(false);
          }
          break;

        default:
          break;
      }
    }
  }, [response]);

  return {
    cloudImages: images,
    cloudError: error,
    cloudUploaderOpen: open,
    cloudLoading: loading,
    cloudUploader: handleOpenUpload,
  };
};

export default useCloudinary;
