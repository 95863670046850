const Download = () => (
  <>
    <path
      d="M7 9.5L10 12.5M10 12.5L13 9.5M10 12.5V1.5M14 2.5H15C17.2091 2.5 19 4.29086 19 6.5V15.5C19 17.7091 17.2091 19.5 15 19.5H5C2.79086 19.5 1 17.7091 1 15.5V6.5C1 4.29086 2.79086 2.5 5 2.5H6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default Download;
