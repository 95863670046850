const Info = () => (
  <>
    <g id="Info">
      <circle cx="99.5" cy="99.5" r="99.5" fill="none" strokeWidth="2" />
      <circle cx="99.5" cy="99.5" r="94.53" fill="none" strokeWidth="2" />
      <g>
        <path
          d="M117.55,140.52l-1.5,6.13q-6.73,2.64-10.74,4a28.42,28.42,0,0,1-9.32,1.4q-8.16,0-12.69-4A12.87,12.87,0,0,1,78.78,138a35.41,35.41,0,0,1,.34-4.87q.34-2.47,1.09-5.61l5.61-19.86c.5-1.9.93-3.71,1.27-5.41a23.9,23.9,0,0,0,.51-4.66c0-2.53-.53-4.3-1.57-5.3s-3-1.5-6-1.5a15.71,15.71,0,0,0-4.47.68c-1.52.46-2.83.89-3.92,1.3l1.5-6.13q5.51-2.24,10.55-3.84a31.76,31.76,0,0,1,9.53-1.6q8.11,0,12.5,3.91a13,13,0,0,1,4.39,10.17c0,.86-.1,2.38-.31,4.56a30,30,0,0,1-1.12,6l-5.59,19.8a52.56,52.56,0,0,0-1.23,5.44,29.08,29.08,0,0,0-.54,4.62c0,2.63.58,4.43,1.76,5.38s3.22,1.43,6.11,1.43a17.4,17.4,0,0,0,4.62-.72A24.16,24.16,0,0,0,117.55,140.52ZM119,57.47a11.61,11.61,0,0,1-3.91,8.81,14.06,14.06,0,0,1-18.88,0,11.53,11.53,0,0,1-3.94-8.81,11.65,11.65,0,0,1,3.94-8.84,14,14,0,0,1,18.88,0A11.73,11.73,0,0,1,119,57.47Z"
          transform="translate(0 -1)"
        />
      </g>
    </g>
  </>
);

export default Info;
