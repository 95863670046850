import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Divider as MuiDivider } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    border: 0,
    backgroundColor: theme.palette.grey[400],
    display: "block",
    height: "auto",
    margin: `${theme.spacing(2.5)}px`,
    overflow: "visible",
    padding: 0,
    position: "relative",
    width: "auto",

    "&$legend:after": {
      content: "attr(title)",
    },

    "&:after": {
      backgroundColor: theme.palette.grey[100],
      // border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: "2em",
      display: "block",
      lineHeight: "1em",
      height: "2em",
      minWidth: "2em",
      padding: "0.4em",
      position: "absolute",
      textAlign: "center",
    },

    "& .icon": {
      height: 50,
      position: "relative",
      top: -25,
      width: 50,

      "& > svg": {
        height: 30,
        width: 30,
      },
    },
  },

  // variant="vertical"
  vertical: {
    minHeight: `calc(100% - ${theme.spacing(5)}px)`,
    width: 1,

    "&:after": {
      // TODO: calculate center position dinamically
      left: "-1em",
      top: "calc(50% - 1em)",
    },
  },

  // variant="horizontal"
  horizontal: {
    height: 1,
    width: `calc(100% - ${theme.spacing(5)}px)`,

    "&:after": {
      left: "50%",
      transform: "translateX(-50%)",
      top: "-0.9em",
    },

    "&$bold": {
      backgroundColor: theme.palette.grey[200],
      margin: `${theme.spacing(2.5)}px 0`,
      height: 2,
      width: "100%",

      "&:after": {
        ...theme.typography.h2,
        fontFamily: theme.atlasGuru.fonts[1],
        color: theme.palette.grey[300],
      },
    },
  },

  // variant="full"
  full: {
    height: 1,
    width: "100%", // Ensures full width
    margin: `${theme.spacing(2.5)}px 0`, // Removes horizontal margin

    "&:after": {
      left: "50%",
      transform: "translateX(-50%)",
      top: "-0.9em",
    },

    "&$bold": {
      backgroundColor: theme.palette.grey[200],
      margin: `${theme.spacing(2.5)}px 0`, // Removes horizontal margin in bold as well
      height: 2,
      width: "100%",

      "&:after": {
        ...theme.typography.h2,
        fontFamily: theme.atlasGuru.fonts[1],
        color: theme.palette.grey[300],
      },
    },
  },

  // type="bold"
  bold: {},

  legend: {},
});

const Divider = ({
  className,
  classes,
  icon,
  legend,
  type,
  variant,
  ...rest
}) => (
  <MuiDivider
    className={`${className} ${classes.root} ${classes[variant]} ${
      classes[type]
    } ${legend ? classes.legend : ""}`}
    component="span"
    title={legend}
    {...rest}
  >
    {icon}
  </MuiDivider>
);

Divider.defaultProps = {
  className: "",
  classes: {},
  icon: null,
  legend: "",
  type: "normal",
  variant: "horizontal",
};

Divider.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  icon: PropTypes.element,
  legend: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export default withStyles(styles)(Divider);
