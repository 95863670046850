const Share = () => (
  <>
    <g data-name="Share – 1">
      <g data-name="Group 29872" transform="translate(12750 21587)">
        <g data-name="Group 29859" transform="translate(-12750 -21587)">
          <g data-name="Group 29844">
            <g data-name="Group 5312" transform="translate(53.124 43.444)">
              <path
                data-name="Path 17624"
                d="M65.427,94.161H0V0H65.427"
                transform="rotate(-90 51.96 51.96)"
                fill="none"
                strokeWidth="5"
              />
              <path
                data-name="Path 10"
                d="M32.364,0,26.48,5.884,48.757,28.161H0v8.406H48.757L26.48,58.844l5.884,5.884L64.729,32.364Z"
                transform="rotate(-90 40.178 24.551)"
              />
            </g>
            <g data-name="Ellipse 315" fill="none" strokeWidth="5">
              <circle cx="100" cy="100" r="100" stroke="none" />
              <circle cx="100" cy="100" r="97.5" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </>
);

export default Share;
