import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";
import Add from "./Add";
import AIStars from "./AIStars";
import Arrow from "./Arrow";
import ArrowThin from "./ArrowThin";
import Calendar from "./Calendar";
import Camera from "./Camera";
import Check from "./Check";
import Circle from "./Circle";
import Close from "./Close";
import CloseV2 from "./CloseV2";
import Cloud from "./Cloud";
import Compass from "./Compass";
import CounterBox from "./CounterBox";
import Delete from "./Delete";
import Desktop from "./Desktop";
import Dragging from "./Dragging";
import Download from "./Download";
import Edit from "./Edit";
import Email from "./Email";
import EmailLight from "./EmailLight";
import Facebook from "./Facebook";
import FacebookColor from "./FacebookColor";
import FacebookLight from "./FacebookLight";
import FaceHappy from "./FaceHappy";
import FaceSad from "./FaceSad";
import Favorite from "./Favorite";
import FullScreen from "./FullScreen";
import FullScreenExit from "./FullScreenExit";
import Gear from "./Gear";
import Globe from "./Globe";
import GoogleColor from "./GoogleColor";
import Grid from "./Grid";
import Guide from "./Guide";
import Hash from "./Hash";
import Heart from "./Heart";
import Help from "./Help";
import Info from "./Info";
import Instagram from "./Instagram";
import InstagramLight from "./InstagramLight";
import Link from "./Link";
import LinkLight from "./LinkLight";
import List from "./List";
import Lodging from "./Lodging";
import MenuBurger from "./MenuBurger";
import MenuDots from "./MenuDots";
import Mobile from "./Mobile";
import MyTrips from "./MyTrips";
import NoteBook from "./NoteBook";
import OrderedList from "./OrderedList";
import Pin from "./Pin";
import Pinterest from "./Pinterest";
import PinterestLight from "./PinterestLight";
import Play from "./Play";
import Question from "./Question";
import RotateLeft from "./RotateLeft";
import RotateRight from "./RotateRight";
import Search from "./Search";
import Share from "./Share";
import ShareAi from "./ShareAi";
import ShareMobile from "./ShareMobile";
import ShareMobileLight from "./ShareMobileLight";
import SignOut from "./SignOut";
import Square from "./Square";
import SquareChecked from "./SquareChecked";
import Travel from "./Travel";
import Twitter from "./Twitter";
import TwitterLight from "./TwitterLight";
import Weather from "./Weather";
import X from "./X";
import XLight from "./XLight";
import Upload from "./Upload";
import User from "./User";
import Warning from "./Warning";
import Tiktok from "./Tiktok";

const styles = (theme) => ({
  // icon styles
  root: {
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",

    "& > svg": {
      fill: "currentColor",
      fontSize: theme.typography.fontSize,
      height: "1em",
      width: "1em",
      pointerEvents: "none",
      position: "relative",
      strokeWidth: 0,
      stroke: "currentColor",
    },
  },

  default: {},

  iconSvg: {
    fill: "currentColor",
    fontSize: theme.typography.fontSize,
    height: "1em",
    width: "1em",
    position: "relative",
    strokeWidth: 0,
    stroke: "currentColor",
  },

  // size variants
  xs: {
    "& svg": {
      height: "1rem",
      width: "1rem",
    },
  },
  sm: {
    "& svg": {
      height: "1.2rem",
      width: "1.2rem",
    },
  },
  md: {
    "& svg": {
      width: "2.2em",
      height: "2.2em",
    },
  },

  // rotation variants
  down: { transform: "rotate(180deg)" },
  left: { transform: "rotate(-90deg)" },
  right: { transform: "rotate(90deg)" },
  up: { transform: "rotate(0deg)" },
  upRightCorner: { transform: "rotate(45deg)" },
});

const IconWrap = ({
  classes = {},
  className = "",
  size = "default",
  variant = "default",
  viewBox = "0 0 200 200",
  withoutSpan = false,
  children,
  ...props
}) => {
  if (withoutSpan) {
    return (
      <SvgIcon
        className={`${classes.iconSvg} ${className} ${classes[variant]} icon`}
        color="inherit"
        viewBox={viewBox}
        {...props}
      >
        {children}
      </SvgIcon>
    );
  }

  return (
    <span
      className={`${classes.root} ${className} ${classes[size]} ${classes[variant]} icon`}
    >
      <SvgIcon
        className={classes.iconSvg}
        color="inherit"
        viewBox={viewBox}
        {...props}
      >
        {children}
      </SvgIcon>
    </span>
  );
};

export const createSvgIcon = (IconSvgPath, defaultProps) => {
  const Icon = (props) => (
    <IconWrap {...defaultProps} {...props}>
      <IconSvgPath />
    </IconWrap>
  );

  return withStyles(styles)(Icon);
};

export const IconAdd = createSvgIcon(Add);
export const IconAIStars = createSvgIcon(AIStars);
export const IconArrow = createSvgIcon(Arrow);
export const IconArrowThin = createSvgIcon(ArrowThin);
export const IconCalendar = createSvgIcon(Calendar);
export const IconCamera = createSvgIcon(Camera);
export const IconCheck = createSvgIcon(Check);
export const IconCircle = createSvgIcon(Circle);
export const IconClose = createSvgIcon(Close);
export const IconCloseV2 = createSvgIcon(CloseV2);
export const IconCloud = createSvgIcon(Cloud);
export const IconCompass = createSvgIcon(Compass);
export const IconCounterBox = createSvgIcon(CounterBox);
export const IconDelete = createSvgIcon(Delete);
export const IconDesktop = createSvgIcon(Desktop);
export const IconDragging = createSvgIcon(Dragging);
export const IconDownload = createSvgIcon(Download);
export const IconEdit = createSvgIcon(Edit);
export const IconEmail = createSvgIcon(Email);
export const IconEmailLight = createSvgIcon(EmailLight);
export const IconFacebook = createSvgIcon(Facebook);
export const IconFacebookColor = createSvgIcon(FacebookColor);
export const IconFacebookLight = createSvgIcon(FacebookLight);
export const IconFaceHappy = createSvgIcon(FaceHappy);
export const IconFaceSad = createSvgIcon(FaceSad);
export const IconFavorite = createSvgIcon(Favorite);
export const IconFullScreen = createSvgIcon(FullScreen);
export const IconFullScreenExit = createSvgIcon(FullScreenExit);
export const IconGear = createSvgIcon(Gear);
export const IconGlobe = createSvgIcon(Globe);
export const IconGoogleColor = createSvgIcon(GoogleColor);
export const IconGrid = createSvgIcon(Grid);
export const IconGuide = createSvgIcon(Guide);
export const IconHash = createSvgIcon(Hash);
export const IconHeart = createSvgIcon(Heart);
export const IconHelp = createSvgIcon(Help);
export const IconInfo = createSvgIcon(Info);
export const IconInstagram = createSvgIcon(Instagram);
export const IconInstagramLight = createSvgIcon(InstagramLight);
export const IconLink = createSvgIcon(Link);
export const IconLinkLight = createSvgIcon(LinkLight);
export const IconList = createSvgIcon(List);
export const IconLodging = createSvgIcon(Lodging);
export const IconMenuBurger = createSvgIcon(MenuBurger);
export const IconMenuDots = createSvgIcon(MenuDots);
export const IconMobile = createSvgIcon(Mobile);
export const IconMyTrips = createSvgIcon(MyTrips);
export const IconNoteBook = createSvgIcon(NoteBook);
export const IconOrderedList = createSvgIcon(OrderedList);
export const IconPin = createSvgIcon(Pin);
export const IconPinterest = createSvgIcon(Pinterest);
export const IconPinterestLight = createSvgIcon(PinterestLight);
export const IconPlay = createSvgIcon(Play);
export const IconQuestion = createSvgIcon(Question);
export const IconRotateLeft = createSvgIcon(RotateLeft);
export const IconRotateRight = createSvgIcon(RotateRight);
export const IconSearch = createSvgIcon(Search);
export const IconShare = createSvgIcon(Share);
export const IconShareAi = createSvgIcon(ShareAi);
export const IconShareMobile = createSvgIcon(ShareMobile);
export const IconShareMobileLight = createSvgIcon(ShareMobileLight);
export const IconSignOut = createSvgIcon(SignOut);
export const IconSquare = createSvgIcon(Square);
export const IconSquareChecked = createSvgIcon(SquareChecked);
export const IconTravel = createSvgIcon(Travel);
export const IconTwitter = createSvgIcon(Twitter);
export const IconWeather = createSvgIcon(Weather);
export const IconX = createSvgIcon(X);
export const IconXLight = createSvgIcon(XLight);
export const IconTwitterLight = createSvgIcon(TwitterLight);
export const IconUpload = createSvgIcon(Upload);
export const IconUser = createSvgIcon(User);
export const IconWarning = createSvgIcon(Warning);
export const IconTiktok = createSvgIcon(Tiktok);
