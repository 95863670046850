const AIStars = () => (
  <>
    <path
      d="M13.6598 9.90373L14.8874 13.7043C16.2527 17.9223 19.2338 21.2433 23.0197 22.7644L26.431 24.132C26.7389 24.2556 26.7389 24.7413 26.431 24.8649L23.0197 26.2325C19.2336 27.7536 16.2527 31.0748 14.8874 35.2926L13.6598 39.0932C13.5489 39.4361 13.113 39.4361 13.002 39.0932L11.7744 35.2926C10.4092 31.0746 7.42813 27.7536 3.6422 26.2325L0.230894 24.8649C-0.0769646 24.7413 -0.0769646 24.2556 0.230894 24.132L3.6422 22.7644C7.42827 21.2433 10.4092 17.9221 11.7744 13.7043L13.002 9.90373C13.1129 9.55862 13.5489 9.55862 13.6598 9.90373Z"
      fill="black"
    />
    <path
      d="M29.0129 0.771285L29.6362 2.69494C30.3284 4.82955 31.8371 6.51248 33.755 7.28368L35.4816 7.97814C35.6384 8.04205 35.6384 8.28704 35.4816 8.34882L33.755 9.04328C31.839 9.81448 30.3284 11.4953 29.6362 13.632L29.0129 15.5557C28.9555 15.7304 28.7356 15.7304 28.6801 15.5557L28.0568 13.632C27.3646 11.4974 25.8559 9.81448 23.938 9.04328L22.2113 8.34882C22.0546 8.28491 22.0546 8.03992 22.2113 7.97814L23.938 7.28368C25.854 6.51248 27.3646 4.83167 28.0568 2.69494L28.6801 0.771285C28.7356 0.594468 28.9574 0.594468 29.0129 0.771285Z"
      fill="black"
    />
    <path
      d="M29.0129 33.446L29.6362 35.3696C30.3284 37.5042 31.8371 39.1871 33.755 39.9583L35.4816 40.6528C35.6384 40.7167 35.6384 40.9617 35.4816 41.0235L33.755 41.718C31.839 42.4892 30.3284 44.17 29.6362 46.3067L29.0129 48.2303C28.9555 48.405 28.7356 48.405 28.6801 48.2303L28.0568 46.3067C27.3646 44.1721 25.8559 42.4892 23.938 41.718L22.2113 41.0235C22.0546 40.9596 22.0546 40.7146 22.2113 40.6528L23.938 39.9583C25.854 39.1871 27.3646 37.5063 28.0568 35.3696L28.6801 33.446C28.7356 33.2713 28.9574 33.2713 29.0129 33.446Z"
      fill="black"
    />
  </>
);

export default AIStars;
